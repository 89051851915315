const elGR = {
  translation: {
    apply: 'Εφαρμογή',
    apr: 'Απρ',
    aug: 'Αύγ',
    bookNow: 'Κράτηση τώρα',
    cancel: 'Ακύρωση',
    categories: '類別',
    category: 'Κατηγορία',
    chinese_simplified: 'Απλοποιημένα Κινεζικά',
    chinese_traditional: 'Παραδοσιακά Κινεζικά',
    clear: 'Εκκαθάριση',
    dateAbbreviated: '{{month}} {{day}}',
    dates: 'Ημερομηνίες',
    datesAvailable: 'Διαθέσιμες ημερομηνίες',
    day: 'ημέρα',
    days: 'ημέρες',
    dec: 'Δεκ',
    dutch: 'Ολλανδικά',
    enterALocation: 'Εισάγετε μια τοποθεσία',
    feb: 'Φεβ',
    filters: 'Φίλτρα',
    french: 'Γαλλικά',
    fromPrice: 'Από',
    fromTime: 'Από',
    german: 'Γερμανικά',
    greek: 'Ελληνικά',
    guest: 'Επισκέπτης',
    guests: 'επισκέπτες',
    hour: 'ώρα',
    hours: 'Ώρες',
    Italian: 'Ιταλικά',
    jan: 'Ιαν',
    jul: 'Ιούλ',
    jun: 'Ιούν',
    kilometers: 'Χιλιόμετρα',
    location: 'τοποθεσία',
    mar: 'Μάρ',
    may: 'Μάιος',
    miles: 'μίλια',
    minutes: 'λεπτά',
    more: 'Περισσότερα',
    next: 'Επόμενο',
    noFilterResults: 'Δυστυχώς, δεν βρέθηκαν αποτελέσματα για τα επιλεγμένα φίλτρα.',
    noResults: 'Λυπούμαστε, δεν μπορέσαμε να βρούμε εμπειρίες.',
    noResultsDates: 'Δυστυχώς, δεν βρέθηκαν αποτελέσματα για τις επιλεγμένες ημερομηνίες.',
    noResultsGuests: 'Δυστυχώς, δεν βρέθηκαν αποτελέσματα για τον επιλεγμένο αριθμό επισκεπτών.',
    noResultsWithin:
      'Δυστυχώς, δεν βρέθηκαν αποτελέσματα εντός <strong>{{distance}} {{units}}</strong> από <strong>{{location}}.</strong>',
    noSearchResults: 'Λυπούμαστε, δεν βρήκαμε αποτελέσματα για "<strong>{{searchQuery}}</strong>".',
    noResultsFuture: 'Λυπούμαστε, δεν έχουμε διαθέσιμες εμπειρίες αυτήν τη στιγμή.',
    numberOfGuests: 'Αριθμός επισκεπτών',
    online: 'Σε σύνδεση',
    previous: 'Προηγούμενο',
    search: 'Αναζήτηση',
    select: 'Επιλογή',
    swedish: 'Σουηδικά',
    to: 'έως',
    widenSearch:
      'Δοκιμάστε να διευρύνετε την αναζήτησή σας αλλάζοντας ή καθαρίζοντας τα φίλτρα σας.',
    within: 'Εντός'
  }
};

export default elGR;
