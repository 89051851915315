import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Theme } from '../../../shared/styles';
import ArrowIcon from '../../../shared/icons/arrow-icon';

const mobileBreakpointWidth = 768;

const cssPrevNextButton = css`
  height: 36px;
  padding: 3px 15px 0px 8px;
  text-decoration: none !important;
  display: inline-block;
  border: solid 1px ${Theme.colors.inputs.borderAction};
  border-radius: 4px;
  width: 120px;
  color: ${Theme.colors.lighterText};

  div {
    position: relative;
  }
  svg {
    fill: ${Theme.colors.icon};
    height: 14px;
    top: 7px;
    position: absolute;
    @media (max-width: ${mobileBreakpointWidth}px) {
      position: absolute;
    }
  }
  @media (max-width: ${mobileBreakpointWidth}px) {
    width: 43px;
  }
`;

export const PrevButton = styled.div`
  padding-left: 8px;
  ::after {
    content: attr(data-content);
  }
  @media (max-width: ${mobileBreakpointWidth}px) {
    ::after {
      content: '';
    }
  }
`;

export const NextButton = styled.div`
  ::before {
    content: attr(data-content);
  }
  @media (max-width: ${mobileBreakpointWidth}px) {
    ::before {
      content: '';
    }
  }
`;

export const LeftArrow = styled(ArrowIcon)`
  transform: rotate(90deg);
  left: 0px;
`;

export const RightArrow = styled(ArrowIcon)`
  transform: rotate(270deg);
  right: 0px;
`;

export const Paginator = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 768px;
  width: 90vw;
  position: relative;
  margin: auto;
  padding-top: 5px;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}` : '100px')};

  opacity: ${props => (props.fade ? '0.4' : '1')};

  /* Tour Listing Pagination */
  ul {
    padding: 0;
    margin-bottom: 0;
  }

  li {
    list-style-type: none;
    display: inline;
    &.disabled {
      opacity: 0.4;
      a {
        color: #333;
        cursor: not-allowed;
        &:hover {
          background-color: #fff;
        }
      }
    }
    a {
      cursor: pointer;
      background: white;
      margin: 0 6px;
      border-radius: 50px;
      color: #333;
      width: 36px;
      height: 36px;
      line-height: 36px;
      display: inline-block;
      text-align: center;
      &:hover {
        background: #f2f2f2;
        text-decoration: none;
      }
    }
    &.active {
      a {
        background: #f2f2f2;
      }
    }

    &.break-me {
      a {
        cursor: default;
      }
    }
  }

  /* Previous and next buttons */
  li.previous {
    position: absolute;
    left: 0px;
    a {
      line-height: 30px;
      text-align: right;
      ${cssPrevNextButton}
    }
  }
  li.next {
    position: absolute;
    right: 0px;
    a {
      line-height: 30px;
      text-align: left;
      ${cssPrevNextButton}
    }
  }
`;
