const deDE = {
  translation: {
    apply: 'Anwenden',
    bookNow: 'Jetzt buchen',
    cancel: 'Abbrechen',
    categories: 'Kategorien',
    category: 'Kategorie',
    chinese_simplified: 'Vereinfachtes Chinesisch',
    chinese_traditional: 'Traditionelles Chinesisch',
    clear: 'Löschen',
    dates: 'Daten',
    datesAvailable: 'Verfügbare Termine',
    day: 'tag',
    days: 'tage',
    dutch: 'Niederländisch',
    english: 'Englisch',
    enterALocation: 'Geben Sie einen Ort ein',
    filters: 'Filter',
    french: 'Französich',
    fromPrice: 'Von',
    fromTime: 'Von',
    german: 'Deutsch',
    greek: 'Griechisch',
    guest: 'Gast',
    guests: 'Gäste',
    hour: 'Stunde',
    hours: 'Stunden',
    kilometers: 'Kilometer',
    location: 'Ort',
    miles: 'Kilometer',
    minutes: 'Minuten',
    more: 'mehr',
    next: 'Weiter',
    noFilterResults:
      'Entschuldigung, wir haben keine Ergebnisse für die ausgewählten Filter gefunden.',
    numberOfGuests: 'Anzahl der Gäste',
    noResults: 'Leider konnten wir keine Erlebnisse finden.',
    noResultsDates:
      'Entschuldigung, wir haben keine Ergebnisse für die ausgewählten Daten gefunden.',
    noResultsGuests:
      'Entschuldigung, wir haben keine Ergebnisse für die ausgewählte Anzahl von Gästen gefunden.',
    noResultsWithin:
      'Entschuldigung, wir haben keine Ergebnisse innerhalb von <strong>{{distance}} {{units}}</strong> von <strong>{{location}}</strong> gefunden.',
    noSearchResults: 'Leider haben wir keine Ergebnisse für "<strong>{{searchQuery}}</strong>".',
    noResultsFuture: 'Es tut uns leid, aber wir haben derzeit keine verfügbaren Erfahrungen.',
    online: 'Online',
    previous: 'Zurück',
    search: 'Suchen',
    soldOut: 'Ausverkauft',
    swedish: 'Schwedisch',
    to: 'Bis',
    widenSearch:
      'Versuchen Sie, Ihre Suche zu erweitern, indem Sie Ihre Filter ändern oder löschen.',
    within: 'Innerhalb'
  }
};

export default deDE;
