import React from 'react';

const CloseIcon = props => (
  <svg width={12} height={12} role="img" {...props}>
    <title>Close</title>
    <path d="M12 .9l-.9-.9L6 5.1.9 0 0 .9 5.1 6 0 11.1l.9.9L6 6.9l5.1 5.1.9-.9L6.9 6z" />
  </svg>
);

export default CloseIcon;
