const zhTW = {
  translation: {
    apply: '套用',
    apr: '4 月',
    aug: '8 月',
    bookNow: '立即預訂',
    cancel: '取消',
    categories: '類別',
    category: '項目',
    chinese_simplified: '簡體中文',
    chinese_traditional: '繁体中文',
    chinese_traditional: '繁體中文',
    clear: '清除',
    dateAbbreviated: '{{month}} {{day}}',
    dates: '日期',
    datesAvailable: '可選日期',
    day: '天',
    days: '天',
    dec: '12 月',
    dutch: '荷蘭語',
    enterALocation: '輸入位置',
    feb: '2 月',
    filters: '篩選條件',
    french: '法語',
    fromPrice: '來自',
    fromTime: '來自',
    german: '德語',
    greek: '希臘語',
    guest: '訪客',
    guests: '訪客',
    hour: '時數',
    hours: '時數',
    Italian: '意大利的',
    jan: '1 月',
    jul: '7 月',
    jun: '6 月',
    kilometers: '公里',
    location: '位置',
    mar: '3 月',
    may: '5 月',
    miles: '英里',
    minutes: '分鐘',
    more: '更多',
    next: '下一步',
    noFilterResults: '抱歉，我們找不到所選篩選條件的結果。',
    noResults: '很抱歉，我們找不到任何體驗。',
    noResultsDates: '抱歉，我們找不到所選日期的結果。',
    noResultsGuests: '抱歉，我們找不到所選訪客人數的結果。',
    noResultsWithin:
      '抱歉，我們找不到在<strong>{{location}}<strong> 的</strong>{{distance}} {{units}} 範圍內的結果。</strong>',
    noSearchResults: '很抱歉，我們找不到結果 "<strong>{{searchQuery}}</strong>".',
    noResultsFuture: '很抱歉，我們目前沒有相應的體驗可提供',
    numberOfGuests: '訪客人數',
    online: '線上',
    previous: '前一頁',
    search: '搜尋',
    select: '选择',
    swedish: '瑞典語',
    to: '至',
    widenSearch: '請透過更改或清除過濾器來擴大搜索範圍。',
    within: '之內'
  }
};

export default zhTW;
