import React from 'react';

export default function LocationIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 10 14"
      xmlSpace="preserve"
      role="img"
      {...props}
    >
      <title>Location</title>
      <g>
        <path
          id="XMLID_5_"
          className="location-icon"
          d="M5,14l-0.3-0.3C4.5,13.5,0,8.2,0,5c0-2.7,2.2-5,5-5s5,2.2,5,5c0,3.2-4.5,8.5-4.7,8.7L5,14z
        		 M5,0.7C2.6,0.7,0.7,2.6,0.7,5c0,2.5,3.3,6.7,4.3,7.9C6,11.7,9.2,7.5,9.2,5C9.2,2.6,7.3,0.7,5,0.7z"
        />
        <path
          id="XMLID_2_"
          className="location-icon"
          d="M5,6.9C3.9,6.9,3.1,6,3.1,5S3.9,3.1,5,3.1S6.9,3.9,6.9,5S6,6.9,5,6.9z M5,3.8
        		C4.3,3.8,3.8,4.3,3.8,5S4.3,6.2,5,6.2S6.2,5.6,6.2,5S5.6,3.8,5,3.8z"
        />
      </g>
    </svg>
  );
}
