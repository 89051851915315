import React from 'react';

const MinusIcon = props => (
  <svg width={16} height={1.8} role="img" {...props}>
    <title>Minus</title>
    <path d="M0 0h16v1.8H0z" />
  </svg>
);

export default MinusIcon;
