/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { geolocatingComplete } from '../../../redux/filters/actions';
import { filterButton } from '../../../shared/utils/staticCssClasses';
import { getLocalStorage } from '../../../shared/utils/getLocalStorage';

import Select from '../../../shared/components/form/Select';
import Popover from '../../../shared/components/Popover';
import PlacesInput from '../../../shared/components/form/PlacesInput';
import LocationIcon from '../../../shared/icons/location-icon';

import Actions from './Actions';

import { filterHeaderCss, FilterButton, PopoverBody } from './filterStyles';
import { capitalize } from '../experience_card/helpers/LocationFilterHelpers';

const Container = styled.div``;

const Label = styled.div`
  display: block;
  margin-bottom: 5px;
  text-align: left;
`;

const Search = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
`;

const Radius = styled.div`
  margin-top: 25px;
  text-align: left;
`;

const LocationIconContainer = styled.div`
  display: flex;
`;

const StyledLocationIcon = styled(LocationIcon)`
  height: 18px;
  fill: white;
  margin-right: 6px;
`;

const LocationFilterButton = styled(FilterButton)`
  max-width: 250px;
`;

const ButtonLabel = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const popoverCss = css`
  width: 350px;
  max-width: 96vw;
`;

const LocationFilter = ({
  mobile,
  partnerCountry,
  useKmForDistance,
  address,
  radius,
  setAddress,
  setRadius,
  selectedLocation,
  setSelectedLocation,
  applied,
  // --- Desktop only props:
  popoverProps,
  buttonLabel,
  applyFilter,
  clearFilter
  // ---
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ariaExpanded, setAriaExpanded] = useState(false);
  const onUserGeolocated = ({ coords }) => {
    dispatch(geolocatingComplete({ coords: `${coords.latitude},${coords.longitude}` }));
  };

  const onUserGeolocatedError = () => {
    dispatch(geolocatingComplete());
  };

  useEffect(() => {
    if (!applied && !getLocalStorage().getItem('locationQuery') && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => onUserGeolocated(position),
        error => onUserGeolocatedError(error),
        { timeout: 5000 }
      );
    } else {
      dispatch(geolocatingComplete());
    }
  }, []);

  const onAddressSelect = locationQuery => {
    setAddress(locationQuery);
    setSelectedLocation(locationQuery);
  };

  const onRadiusChange = locationRadius => {
    setRadius(Number(locationRadius.value));
  };

  let units = useKmForDistance ? t('kilometers') : t('miles');
  units = capitalize(units);

  const radiusOptions = [
    { value: 25, displayValue: 25, label: `25 ${units}` },
    { value: 50, displayValue: 50, label: `50 ${units}` },
    { value: 100, displayValue: 100, label: `100 ${units}` }
  ];

  let radiusOption = radiusOptions.find(r => r.displayValue === radius);
  if (!radiusOption) radiusOption = null;

  // Restrict autocomplete results by country in PlacesInput
  let componentRestrictions = null;
  if (partnerCountry && partnerCountry.length > 0) {
    componentRestrictions = { country: partnerCountry };
  }

  const searchOptions = {
    componentRestrictions,
    types: ['(regions)']
  };

  const filter = (
    <Container data-testid="location-filter-widget">
      <Label css={filterHeaderCss}>{t('location')}</Label>
      <Search>
        <PlacesInput
          searchOptions={searchOptions}
          address={address}
          setAddress={setAddress}
          selectedLocation={selectedLocation}
          data-testid="location-input"
          onAddressSelect={onAddressSelect}
          cityAndZipOnly
        />
      </Search>
      <Radius>
        <Label css={filterHeaderCss}>{t('within')}</Label>
        <Select
          value={radiusOption}
          onChange={onRadiusChange}
          options={radiusOptions}
          isSearchable={false}
          placeholder={`${t('select')}...`}
        />
      </Radius>
      {!mobile && (
        <Actions
          clear={clearFilter}
          apply={applyFilter}
          applyDisabled={!selectedLocation || !radius}
          hideClear
        />
      )}
    </Container>
  );

  if (mobile) {
    return filter;
  }

  return (
    <Popover {...popoverProps} content={<PopoverBody css={popoverCss}>{filter}</PopoverBody>}>
      <LocationFilterButton
        className={filterButton}
        data-testid="location-filter"
        applied={applied}
        aria-expanded={ariaExpanded}
      >
        {applied ? (
          <LocationIconContainer>
            <StyledLocationIcon />
          </LocationIconContainer>
        ) : null}
        <ButtonLabel>{buttonLabel || 'Location'}</ButtonLabel>
      </LocationFilterButton>
    </Popover>
  );
};

export default LocationFilter;
