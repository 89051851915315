const frCA = {
  translation: {
    apply: 'Appliquer',
    bookNow: 'Reserve',
    cancel: 'Annuler',
    categories: 'Catégories',
    category: 'Catégorie',
    chinese_simplified: 'Chinois simplifié',
    chinese_traditional: 'Chinois traditionnel',
    clear: 'Clair',
    clearAll: 'Effacer',
    dates: 'Dates',
    datesAvailable: 'Dates disponibles',
    day: 'jour',
    days: 'jours',
    dutch: 'Néerlandais',
    english: 'Anglais',
    enterALocation: 'Entrez un emplacement',
    filters: 'Filtres',
    french: 'French',
    fromPrice: 'De',
    fromTime: 'De',
    german: 'Allemand',
    greek: 'Grec',
    guest: 'Invité',
    guests: 'Invités',
    hour: 'heure',
    hours: 'heures',
    kilometers: 'Kilomètres',
    location: 'Emplacement',
    miles: 'kilomètres',
    minutes: 'minutes',
    more: 'plus',
    next: 'Suivant',
    noFilterResults: "Désolé, nous n'avons trouvé aucun résultat pour les filtres sélectionnés.",
    noResults: "Désolé, nous n'avons trouvé aucune expérience.",
    noResultsDates: "Désolé, nous n'avons trouvé aucun résultat pour les dates sélectionnées.",
    noResultsFuture: "Désolé, nous n'avons pas d'expérience disponible pour le moment.",
    noResultsGuests:
      "Désolé, nous n'avons trouvé aucun résultat pour le nombre d'invités sélectionné.",
    noResultsWithin:
      "Désolé, nous n'avons trouvé aucun résultat dans un rayon de <strong>{{distance}} {{units}}</strong> de <strong>{{location}}.</strong>",
    noSearchResults:
      'Désolé, nous n\'avons trouvé aucun résultat pour "<strong>{{searchQuery}}</strong>".',
    noResultsFuture: "Désolé, nous n'avons pas d'expérience disponible pour le moment.",
    numberOfGuests: 'Nombre de personnes',
    online: 'En ligne',
    previous: 'Retour',
    search: 'Chercher',
    select: 'Sélectionnez',
    soldOut: 'Épuisé',
    swedish: 'Suédois',
    to: 'À',
    widenSearch: "Essayez d'élargir votre recherche en modifiant ou en effaçant vos filtres.",
    within: 'Dans un rayon de'
  }
};

export default frCA;
