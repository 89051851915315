import React from 'react';

const PlusIcon = props => (
  <svg width={16} height={16} role="img" {...props}>
    <title>Plus</title>
    <path d="M16 7.1H8.9V0H7.1v7.1H0v1.8h7.1V16h1.8V8.9H16z" />
  </svg>
);

export default PlusIcon;
