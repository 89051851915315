const zhCN = {
  translation: {
    apply: '应用',
    apr: '4 月',
    aug: '8 月',
    bookNow: '立即预订',
    cancel: '取消',
    categories: '类别',
    category: '项目',
    chinese_simplified: '简体中文',
    chinese_traditional: '繁体中文',
    clear: '清除',
    dateAbbreviated: '{{month}} {{day}}',
    dates: '日期',
    datesAvailable: '可选日期',
    day: '天',
    days: '天',
    dec: '12 月',
    dutch: '荷兰语',
    enterALocation: '输入位置',
    feb: '2 月',
    filters: '筛选器',
    french: '法语',
    fromPrice: '从',
    fromTime: '从',
    german: '德语',
    greek: '希腊语',
    guest: '客人',
    guests: '客人',
    hour: '小时',
    hours: '小时',
    italian: '意大利语',
    jan: '1 月',
    jul: '7 月',
    jun: '6 月',
    kilometers: '千米',
    location: '位置',
    mar: '3 月',
    may: '5 月',
    miles: '英里',
    minutes: '分钟',
    more: '更多',
    next: '下一个',
    noFilterResults: '抱歉，我们找不到符合所选筛选条件的结果。',
    noResults: '抱歉，找不到任何体验。',
    noResultsDates: '抱歉，我们找不到所选日期的结果。',
    noResultsGuests: '抱歉，我们找不到选定人数访客的结果。',
    noResultsWithin:
      '抱歉，我们在<strong>{{location}}</strong>的 <strong>{{distance}} {{units}}</strong>范围内找不到结果。',
    noSearchResults: '抱歉，找不到以下内容的结果："<strong>{{searchQuery}}</strong>".',
    noResultsFuture: '很抱歉，我们目前没有相应的体验可提供。',
    numberOfGuests: '客人数量',
    online: '在线',
    previous: '上一页',
    search: '搜索',
    select: '选择',
    swedish: '瑞典语',
    to: '到',
    widenSearch: '请透过更改或清除过滤器来扩大搜索范围。',
    within: '距离'
  }
};

export default zhCN;
