const nlNL = {
  translation: {
    apply: 'Toepassen',
    bookNow: 'Nu reserveren',
    cancel: 'Annuleren',
    categories: 'Categorieën',
    category: 'Categorie',
    chinese_simplified: 'Vereenvoudigd Chinees',
    chinese_traditional: 'Traditioneel Chinees',
    clear: 'Wissen',
    clearAll: 'Wissen alles',
    dates: 'Datums',
    datesAvailable: 'Data beschikbaar',
    day: 'dag',
    days: 'dagen',
    dutch: 'Nederlands',
    english: 'Engels',
    enterALocation: 'Voer een locatie in',
    filters: 'Filters',
    french: 'Frans',
    from: 'Van',
    fromPrice: 'Van',
    fromTime: 'Van',
    german: 'Duits',
    greek: 'Grieks',
    guest: 'Gast',
    guests: 'Gasten',
    hour: 'uur',
    hours: 'uren',
    kilometers: 'Kilometer',
    location: 'Locatie',
    miles: 'Mijl',
    minutes: 'minuten',
    more: 'Meer',
    multipleLocations: 'Meerdere locaties',
    next: 'Volgende',
    noFilterResults: 'Sorry, we hebben geen resultaten gevonden voor de geselecteerde filters.',
    noResults: 'Het spijt ons, we konden geen experiences vinden.',
    noResultsDates: 'Sorry, we hebben geen resultaten gevonden voor de geselecteerde datums.',
    noResultsGuests:
      'Sorry, we hebben geen resultaten gevonden voor het geselecteerde aantal gasten.',
    noResultsWithin:
      'Sorry, we hebben geen resultaten gevonden binnen <strong>{{distance}} {{units}}</strong> van <strong>{{location}}.</strong>',
    noSearchResults:
      'Sorry, we hebben geen resultaten gevonden voor "<strong>{{searchQuery}}</strong>".',
    noResultsFuture: 'Sorry, we hebben momenteel geen beschikbare ervaringen.',
    numberOfGuests: 'Aantal gasten',
    online: 'Online',
    previous: 'Vorige',
    search: 'Zoeken',
    select: 'Aantal gasten',
    soldOut: 'uitverkocht',
    swedish: 'Zweeds',
    to: 'Tot',
    widenSearch: 'Probeer uw zoekopdracht te verbreden door uw filters te wijzigen of te wissen.',
    within: 'Binnen'
  }
};

export default nlNL;
